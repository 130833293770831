export const portals = [
  {
    year: 2020,
    home: {
      mainText: "Intermountain Healthcare Live Events",
      // secondaryText: "Summer CE Sale until midnight September 7th",
      list: [
        // "24-hours access to the educational content",
        // "Online CE courses",
        // "Available on mobile devices"
      ],
      ctaText: "Sign Up",
      ctaTextLoggedIn: "Browse Courses",
    }
  },
];

// export const signUpFormData = {
//   size: 'sm', // or md, fullscreen
//   fields: [
//     { id: "phone", label: "Phone Number", type: "phone", mandatory: true, defaultValue: "", xs: 12, sm: 6, md: 6},
//     { id: "accId", label: "Account ID", type: "text", maxLength: 4, mandatory: true, regex: "^\\d*$", defaultValue: "", xs: 12, sm: 6, md: 6, autoComplete: undefined},
//     { id: "allowShare", label: "Share contact data", type: "radio", mandatory: true, options: [{ value: "1", label: "Yes" }, { value: "", label: "No" }], defaultValue: null, row: true, xs: 12, sm: 6, md: 6 },
//     { id: "country", label: "Country", type: "select", mandatory: true, options: [{ value: "1", label: "US" }, { value: "0", label: "Other" }], defaultValue: "1", xs: 12, sm: 6, md: 6 },
//   ]
// }

export const tiers = [
  {
    id: 0,
    title: 'Session',
    price: '60',
    memberPrice: '60',
    description: ['Individual session'],
    buttonText: 'Select',
    buttonVariant: 'outlined',
    summary: "Individual Session"
  },
  // {
  //   id: 1,
  //   title: 'Track',
  //   subheader: 'Most popular',
  //   price: '250',
  //   memberPrice: '195',
  //   description: [
  //     'Four sessions',
  //     'Keynote presentation',
  //   ],
  //   buttonText: 'Select',
  //   buttonVariant: 'contained',
  //   summary: "Track (4 sessions)"
  // },
  // {
  //   id: 2,
  //   title: 'Conference',
  //   price: '450',
  //   memberPrice: '395',
  //   description: [
  //     'All sessions and keynote presentation',
  //   ],
  //   buttonText: 'Select',
  //   buttonVariant: 'outlined',
  //   summary: "Entire Conference"
  // },
];

export const sponsors = {
  diamond: [
    { name: 'ABB', url: 'http://www.abbconcise.com/', image: 'Diamonds/ABB Optical 2020 300.png' },
    { name: 'aller', url: 'http://www.allerganoptometry.com/', image: 'Diamonds/Allergan 300.jpg' },
    { name: 'bl', url: 'http://www.bausch.com/', image: 'Diamonds/B&L Logo 2014.jpg' },
    { name: 'hea', url: 'http://www.hea2020.com/', image: 'Diamonds/Hea300.jpg' },
    { name: 'gand', url: 'http://www.thegandelmanagency.com/', image: 'Diamonds/gandelman_square_logo_17.jpg' },
    { name: 'oomc', url: 'http://www.omnieyeservices.com/', image: 'Diamonds/OOMC_Logo_Blue.png' },
  ],
  gold: [
    { name: 'john', url: 'http://www.jnjvisionpro.com/coronavirus', image: 'Golds/JJV2018.jpg' },
    { name: 'novar', url: null, image: 'Golds/novartis_logo_pos_rgb.jpg' },
    { name: 'expo', url: 'http://visionexpony2020.com/NJSOP', image: 'Golds/images.png' },
    { name: 'vsp', url: 'https://www.vsp.com/', image: 'Golds/VSP_Global2017.jpg' },
  ],
  silver: [
    { name: 'idoc', url: 'http://bit.ly/2RdThEk', image: 'Silvers/IDOC_Tagline_Outlined-noreg-cmyk.jpg' },
    { name: 'vti', url: 'https://www.njsop.org/aws/NJSOP/asset_manager/get_image/164294?ver=8', image: 'Silvers/VTI2018logo.jpg' },
  ],
  bronze: [
    { name: 'drtravel', url: 'http://www.drtravel.com/Seminars.html', image: 'Bronze/DrTravel.jpg' },
    { name: 'luz', url: 'http://www.luzerneoptical.com/?sl=EN', image: 'Bronze/Luzerne Logo 2014 web.jpg' },
    { name: 'metro', url: 'https://www.metroophthalmic.com/', image: 'Bronze/02453f_e176ca54f61046c0b33ca0bb9b55d18f_mv2_d_5176_1900_s_2.png' },
    { name: 'sheridan', url: 'http://www.sheridanoptical.com/', image: 'Bronze/Sheridan Optical.JPG' },
    { name: 'sun', url: 'http://www.sunpharma.com/', image: 'Bronze/SunOp300.jpg' },
  ],
}

export const eventsContent = [];

export const menu = []
export const individuals = {
  list: [],
  relations: [],
  commercial: [],
  about2018: [],
  about2019: [],
  objectives: [],
  statements: [],
  method: []
}
