import React, { Fragment } from "react";
// import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import uiConfig from '../uiConfig';
import Copyright from "./Copyright";


const useStyles = makeStyles(theme => ({
  footer: {
    flexShrink: 0,
    background: '#fff',
    zIndex: 1,
  },
  social: {
    display: 'inline-block',
    marginTop: -10,
    "& > a": {
      color: '#000',
      margin: theme.spacing(0, 1),
    }
  },
  links: {
    display: 'inline-block',
    float: 'right',
    "& > a": {
      color: '#000',
      textDecoration: 'none',
      // margin: theme.spacing(0, 1),
    }
  }
}));

const Footer = ({ links }) => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container>
        <br />
        <br />
        <div className={classes.social}>
          {uiConfig.footer.facebookUrl && <MuiLink href={uiConfig.footer.facebookUrl} target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faFacebook} size="2x" /></MuiLink>}
          {uiConfig.footer.twitterUrl && <MuiLink href={uiConfig.footer.twitterUrl} target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faTwitter} size="2x" /></MuiLink>}
          {uiConfig.footer.instagramUrl && <MuiLink href={uiConfig.footer.instagramUrl} target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faInstagram} size="2x" /></MuiLink>}
          {uiConfig.footer.linkedInUrl && <MuiLink href={uiConfig.footer.linkedInUrl} target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faLinkedin} size="2x" /></MuiLink>}
        </div>
        <div className={classes.links}>
          {links.map((link, index) => (
            <Fragment key={link.url}>
              <MuiLink component={Link} to={link.url} variant="body2">{link.title.toUpperCase()}</MuiLink>{(index === links.length - 1 && !uiConfig.showPrivacyPolicy) ? "" : " | "}
            </Fragment>
          ))}
          {/* {uiConfig.showPrivacyPolicy && <MuiLink component={Link} to="/privacy" variant="body2">PRIVACY</MuiLink>} */}
          {uiConfig.showPrivacyPolicy && <MuiLink component={Link} to="/privacy" variant="body2">PRIVACY</MuiLink>}
        </div>
        <br />
        <Copyright />
      </Container>
    </footer>
  );
}

export default Footer;
