import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import uiConfig from '../../uiConfig';

import { inject } from "mobx-react";
import usePortal from "../hooks/portal";
import Sponsors from "../Sponsors";
import { LogOnMount } from 'react-amplitude-hooks';
import EventsGrid from "../EventsGrid";

const useStyles = makeStyles(theme => {
  return ({
    fullWidth: ({ height, minHeight }) => ({
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      height,
      minHeight,
      backgroundSize: 'cover !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    paper: ({ paperMarginRight, paperMaxWidth, paperColor }) => ({
      // marginRight: paperMarginRight,
      maxWidth: paperMaxWidth,
      //background: 'rgba(0,0,0,.3)',
      background: paperColor,
      padding: '2vw 3vw',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '80vw',
        padding: '30px 20px',
        margin: '0 auto',
      },
    }),
    slider: {
      marginRight: '15vw',
      // maxHeight: 300,
      maxWidth: 350,
      minWidth: 350,
      "& img": {
        // maxHeight: 400,
      }
    },
    media: {
      height: 350,
    },
    ctaContainer: {
      textAlign: 'center',
      width: '100%',
      marginTop: 15
    },
    heading: ({ fontFamily, fontWeight, color }) => ({
      color, //theme.palette.primary.main,
      textAlign: 'center',
      fontFamily,
      fontWeight,
    }),
    list: ({ fontFamily, fontWeight, color }) => ({
      color, //theme.palette.primary.main,
      fontFamily,
      fontWeight,
      fontSize: '1.2rem',
    }),
    ctaButton: ({ fontWeight }) => ({
      margin: 10,
      padding: theme.spacing(2, 4),
      fontWeight: fontWeight,
    }),
    sponsors: {
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      // paddingLeft: 20,
      textAlign: 'center',
      "& h4": {
        fontWeight: 600,
      },
      "& hr": {
        borderColor: theme.palette.secondary.main,
        width: '94%',
        marginBottom: 30,
      }
    },
    events: {
      "& h4": {
        fontWeight: 600,
        textAlign: 'center',
      },
      "& hr": {
        borderColor: theme.palette.secondary.main,
        // width: '94%',
        marginBottom: 10,
      }
    },
  })
});

const HomePage = ({ isLoggedIn, store, events }) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [backgroundImage, setBackgroundImage] = useState("");
  const { urlPrefix, data: { home: data }, uiSettings: { home: uiSettings }, uiSettings: { signInRedirect } } = usePortal();
  const classes = useStyles(uiSettings);
  let background = `url(${backgroundImage}) ${uiSettings.backgroundPosition.x}% ${uiSettings.backgroundPosition.y}% no-repeat`;

  useEffect(() => {
    if (backgroundImage)
      return;
    store.setIsFetching(true);
    const loadBackground = async () => {
      const { default: url } = await import('../../assets/images/' + uiSettings.backgroundUrl);
      //console.log(url);
      setBackgroundImage(url);
      store.setIsFetching(false);
    }
    loadBackground();
  }, [uiSettings.backgroundUrl, store, backgroundImage]);
  if (!backgroundImage) return null;
  return (
    <>
      <LogOnMount eventType="home visit" eventProperties={{ page: "home" }} />
      {uiConfig.showHero &&
        <div className={classes.fullWidth} style={{ background }}>
          {/* <Slider interval={2000} animation="fade">
          {sliderItems.map(item => (
            <Paper key={item.image} className={classes.slider} elevation={4}>
              <Card key={item.image}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={item.image}
                    title={item.text}
                    style={{ backgroundPosition: `50% ${item.backgroundPosition}%` }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {item.text}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {item.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Paper>
          ))}
        </Slider> */}
          <Paper className={classes.paper} elevation={1}>
            <Typography variant={isSm ? "h5" : "h4"} className={classes.heading} gutterBottom>{data.mainText}</Typography>
            {/* <Typography variant="subtitle1" className={classes.heading}>{data.secondaryText}</Typography> */}
            {/* <Typography component="div" className={classes.list}>
            <ul>
              {data.list.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
          </Typography> */}
            {!isLoggedIn && uiConfig.showCta &&
              <div className={classes.ctaContainer}>
                <Button className={classes.ctaButton} to={urlPrefix + ((isLoggedIn || !uiConfig.requireAuth) ? signInRedirect : uiSettings.getLink)} component={Link} variant="contained" color={uiSettings.buttonColor} size="large">{(isLoggedIn || !uiConfig.requireAuth) ? data.ctaTextLoggedIn : data.ctaText}</Button>
              </div>
            }
          </Paper>
        </div>
      }
      {(uiConfig.showEvents && events.length > 0) &&
        <div className={classes.events}>
          <br />
          <Typography variant="h4" color="primary" gutterBottom>Upcoming Events</Typography>
          <hr />
          <EventsGrid events={events} />
          <br />
          <br />
        </div>
      }
      {uiConfig.showSponsors &&
        <div className={classes.sponsors}>
          <br />
          <Typography variant="h4" color="primary" gutterBottom>Diamond Level Partners</Typography>
          <hr />
          <Sponsors level="diamond" />
          <Typography variant="h4" color="primary" gutterBottom>Gold Level Partners</Typography>
          <hr />
          <Sponsors level="gold" />
          <Typography variant="h4" color="primary" gutterBottom>Silver Level Partners</Typography>
          <hr />
          <Sponsors level="silver" />
          <Typography variant="h4" color="primary" gutterBottom>Bronze Level Partners</Typography>
          <hr />
          <Sponsors level="bronze" />
        </div>
      }
    </>
  );
}

export default inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn, store, events: store.events
}))(HomePage);
