const config = {
  CMEfilter: false,
  mainColor: "#00346a",
  primaryContrastText: "#fff",
  secondaryColor: "#38d",
  secondaryContrastText: "#fff",
  searchPlaceholder: "What do you want to learn?",
  loginText: "",
  requireAuth: true,
  showBrowse: false,
  showBuy: false,
  showSignUp: false,
  showHowTo: false,
  showAbout: false,
  showSponsors: false,
  showEvents: true,
  showHero: false,
  showCta: false,
  trackPageScrollOffset: -80,
  showExhibitHall: true,
  showChat: true,
  supportEmail: "info@slidespiel.com",
  portals: [
    { 
      year: 2020,
      logo: 'logo.png',
      signInRedirect: "/",
      home: {
        minHeight: 400,
        height: '40vh',
        paperMarginRight: '10vw',
        paperMaxWidth: '40vw',
        paperColor: 'rgba(0,0,0,.5)',
        backgroundUrl: '_background.jpg',
        backgroundPosition: { x: 50, y: 45 },
        color: "#fff",
        buttonColor: "secondary",
        getLink: "/signup",
        //fontFamily: "Fira Sans",
        //fontWeight: 'bold',
      },
    },
  ],
  main: {
    logoMarginTop: 5,
    logoPadding: 0,
    logoHeight: 90,
    logoHeightXs: 50,
    progressBarTop: 100,
    progressBarTopXs: 55,
    childrenMarginTop: 100,
    childrenMarginTopXs: 50,
    searchColor: "primary",
    barColor: "default",
    barTextColor: "primary",
    barButtonColor: "primary"
  },
  footer: {
    // facebookUrl: "https://www.facebook.com/",
    // twitterUrl: "https://twitter.com/",
    // instagramUrl: "https://instagram.com/",
    // linkedInUrl: "https://www.linkedin.com/",
  },
  eventHome: {
    titleColor: "primary",
    subtitleColor: "secondary",
    liveColor: "primary",
    registerColor: "primary",
    exhibitHallColor: "secondary",
    networkingRoomColor: "primary",
  }
};

export default config;
