import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme, /* fade */ } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { Link } from "react-router-dom";
// import usePortal from '../hooks/portal';
import RestrictedAccessPage from "./RestrictedAccessPage";
import api from '../../api';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  headerTile: {
    // height: 'auto !important'
  },
  tile: {
    '& img': {
      transition: 'transform .4s ease',
    },
    '&:hover': {
      '& img': {
        transform: 'scale(1.05) translateY(-50%)',
      }
    }
  },
  bar: {
    // backgroundColor: fade(theme.palette.primary.main, 0.7),
    height: 50
  },
  barTitle: {
    // fontSize: '0.92rem'
  }
}));

const ExhibitHall = ({ event, store }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    let didCancel = false;
    if (!event.id) return
    const getData = async () => {
      store.setIsFetching(true);
      const data = await api.getExhibitors(event.id);
      if (didCancel) return;
      console.log(data);
      setData(data);
      store.setIsFetching(false);
    }
    getData();
    return () => { didCancel = true }
  }, [event.id]);

  if (!event.hasAccess) {
    return (<RestrictedAccessPage />);
  }

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <br />
      <br />
      <GridList cellHeight={230} cols={isSm ? 2 : 5} spacing={1}>
        {data.map((item, index) => (
          <GridListTile className={classes.tile} component={Link} to={"/event/" + event.alias + "/exhibitor/" + item.id} key={item.id} >
            <img src={item.imageUrl} alt={item.title} />
            <GridListTileBar
              title={item.title}
              className={classes.bar}
              classes={{ title: classes.barTitle }}
              subtitle={<span>{item.subtitle}</span>}
            />
          </GridListTile>
        ))}
      </GridList>
    </>
  );
}

export default ExhibitHall;